<template>
	<section>
		<b-row class="mb-2">
		    <b-col lg="12">
				<Breadcrumb :labels="labels" />				
		    </b-col>		    
		</b-row>		
		<b-row>
		    <b-col md="12">
		    	<div class="relative">
			        <b-tabs card class="caption-add table-custom">                       
			            <b-tab title="Basic Info" active>
			            	<validation-observer
						        ref="announcement_add"
						        #default="{invalid}" >
				                <b-form @submit.prevent class="profile-frm" style="border-radius: 5px; margin-top: 10px">
				                	<b-row class="align-center">
							            <b-col lg-12>
							            	<div class="text-right mb-2">
							                    <b-button type="button" variant="basic" class="btn-df size-18 black" style="box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25); margin-right: 10px " @click="cancelAction">Cancel</b-button>

							                    <template v-if="userData">

								                    <b-button type="button" variant="primary" class="btn-df size-18 black btn-dark-green" style="margin-right: 10px " @click="saveDraft(2)">
								                        Save Draft
								                    </b-button>
								                    <b-button type="button" variant="primary" class="btn-df size-18 black btn-dark-blue" style="margin-right: 10px " @click="schedule">
								                        Schedule
								                    </b-button>
								                    <b-button type="button" variant="primary" class="btn-df size-18 black" @click="publish(1)">
								                        Publish
								                    </b-button>

								                </template>
								                <template v-else>
								                	<b-button type="button" variant="primary" class="btn-df size-18 black btn-dark-green btn-disabled-gray" disabled style="margin-right: 10px ">
								                        Save Draft
								                    </b-button>
								                    <b-button type="button" variant="primary" class="btn-df size-18 black btn-dark-blue btn-disabled-gray" disabled style="margin-right: 10px ">
								                        Schedule
								                    </b-button>
								                    <b-button type="button" variant="primary" class="btn-df size-18 black btn-disabled-gray" disabled>
								                        Publish
								                    </b-button>
								                </template>
							                </div>
							            </b-col>
							        </b-row>				                
					                <div style="border-top: 1px solid #000000B2;" class="pb-2 mb-10"></div>
					                <div class="frm-basic size-16 d-block">              
				                      	<b-row style="width: 100%; margin: 0;"> 
				                          	<b-col lg="6" class="px-0">             
					                            <b-form-group label="AAID" label-cols-lg="4" class="star-required input-group-label">
				                                    <b-form-input
				                                    	v-model="fields.id"
				                                      	placeholder="Useful Info (P) ID"
				                                      	disabled
				                                    ></b-form-input>
					                            </b-form-group>
				                          	</b-col> 	
				                          	<b-col lg="6" class="px-0">             
					                            <b-form-group label="Published Date" label-cols-lg="4" class="star-required">
				                                     <flat-pickr
					                                      	class="form-control"   
					                                      	v-model="fields.published_date"
					                                      	placeholder="dd/mm/yy"
					                                      	disabled
					                                    />
					                            </b-form-group>
				                          	</b-col> 			                          		
				                          	<b-col lg="6" class="px-0">	
				                          		<b-form-group label="Image Banner (1600 x 900)" label-cols-lg="4" class="uploadImage pl-1600-lg-10">
				                          			<div class="upload-show-name container-box" v-if="fields.image" style="flex-direction: column">
				                          				<div class="close-upload cursor-pointer" @click="closeUpload">
				                          					<feather-icon
																icon="XCircleIcon"
																size="24"
																color="#DD3E47"
															/>
				                          				</div>
				                          				<p>
				                          					<feather-icon
																icon="ImageIcon"
																size="30"
															/>
				                          				</p>				
					                          			{{ fields.image ? fields.image.name : '' }}
					                          		</div>                   		
				                          			<div v-else class="bg container-box" :style="{ backgroundImage: `url(${require('@/assets/images/itees/admin/Upload-image.png')})` }">
							                            <b-form-file 
							                            	v-model="fields.image" 
							                            	plain 
							                            	accept=".jpg, .png" 
							                            	ref="fileImage"
															class="cursor-pointer"
															>
					                                    </b-form-file>		                 
					                                </div>
					                               
				                                </b-form-group>
				                          	</b-col> 	 	
				                          	<b-col lg="6" class="px-0">             
					                            <b-form-group label="Status" label-cols-lg="4" class="star-required">	
					                                <v-select
					                                    label="title"
					                                    class="disabled-field"
					                                    :options="selectStatus"
					                                    v-model="fields.status" 
					                                    placeholder="Select status"
					                                    disabled
				                                    />
					                            </b-form-group>
				                          	</b-col> 
				                          	<b-col lg="12" class="px-0">             
				                              	<b-form-group label="Title" label-cols-lg="2" class="star-required input-group-label">
				                              		<validation-provider
					                                  #default="{ errors }"
					                                  name="Title"
					                                  vid="title"
					                                  rules="required"                
					                                >
					                                    <b-form-input
					                                    	v-model="fields.title" 
					                                      	placeholder="Title"
					                                      	:formatter="length100"
					                                    ></b-form-input>    
					                                 	<small class="text-danger">{{ errors[0] }}</small>
                                					</validation-provider>              
				                              	</b-form-group>
				                          	</b-col>
				                          	<b-col lg="12" class="px-0">             
				                              	<b-form-group label="Description" label-cols-lg="2" class="star-required"> 
				                              		<validation-provider
					                                  #default="{ errors }"
					                                  name="Description"
					                                  vid="description"
					                                  rules="required"               
					                                >                           
					                                    <b-form-textarea
					                                    	v-model="fields.description"
					                                      	placeholder="Description"
					                                      	rows="5"
					                                      	:formatter="length1000"
					                                    ></b-form-textarea>
					                                    <small class="text-danger">{{ errors[0] }}</small>
				                                    </validation-provider>       
				                              	</b-form-group>
				                          	</b-col>
				                      	</b-row>
					                </div>
					            </b-form> 
					            <b-modal
								    id="modal-Schedule"
								    ref="modaSchedulel"
								    title="Schedule Content Info"	
								    :no-close-on-backdrop="true"	
								    @close="cancelSchedule"				   
								>
								    <b-row style="padding-right: 30px">
				                    	<b-col lg="12" class="px-0">
				                            <b-form-group label="Date From" label-cols-xl="3"
				                              label-cols-md="3" label-class="text-lg-right bold" class="star-required">
												<div class="calendarIcon">
													<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24"><path fill="WindowText" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/><path fill="none" d="M0 0h24v24H0z"/></svg>
												</div>
												<flat-pickr
													v-model="date_schedule"
													placeholder="Select a date "
													class="form-control fieldCalendar"           
													:config="{ altInput: true,altFormat: 'd/m/y', dateFormat: 'Y-m-d', minDate: minScheduleDate}"
													required
												/>
				                            </b-form-group>
				                        </b-col>
				                        <b-col lg="12" class="px-0">
				                            <b-form-group label="Time" label-cols-xl="3"
				                              label-cols-md="3" label-class="text-lg-right bold" class="star-required">
				                              	<validation-provider
					                                #default="{ errors }"
					                                name="Time"
					                                vid="time_schedule"
					                                rules="required"               
					                            >   
											        <v-select
											        	v-model="time_schedule"
					                                    label="time"
					                                    :options="getListTime"
					                                    placeholder="00:00"
					                                    class="timebox black-child-im form-control px-0 border-none"
					                                />
					                                <small class="text-danger">{{ errors[0] }}</small>
					                            </validation-provider>
				                            </b-form-group>
				                        </b-col>
				                    </b-row>   
				                    <template #modal-footer="{ cancel }">
				                        <b-button size="sm" variant="static" class="btn-default" @click="cancelSchedule">
				                          	Cancel
				                        </b-button>
				                        <b-button size="sm" variant="primary" class="btn-primary" @click="saveSchedule" id="btn-dark-blue">
				                          	Schedule
				                        </b-button>
				                    </template> 
								</b-modal>
					        </validation-observer> 
			            </b-tab>               
			        </b-tabs>   
			        <div class="table-custom">
			        	<b-card class="px-table-0 document-table w-50-percent-cl-3 w-17-percent-cl-2 w-17-percent-cl-2">
		            		<div class="text-right mr-2 mb-2">
			                    <b-button type="button" variant="primary" class="btn-df size-18 black btn-disabled-gray" disabled>
			                        Add Document
			                    </b-button>					                    
			                </div>
			                <b-table small :fields="fields" :items="files_document" tbody-tr-class="size-14 table-row" show-empty>
			                	<template #head()="data">
						    		<span class="d-block text-center">{{ data.label }}</span>
						    	</template>	
						    	<template #head(id)="data">
									<span>{{ data.label }}</span>
								</template>
			                	<template #empty="scope">
							      	<h4 class="my-3 text-center" style="color: #CFCFCF !important; font-size: 14px !important">No data</h4>
							    </template>
			                </b-table>
		            	</b-card>	  
			        </div>  
			    </div>
			</b-col>
		</b-row>
	</section>
</template>
<script>

	import Breadcrumb from '@/views/itees/admin-dashboard/partial/Breadcrumb';
	import flatPickr from 'vue-flatpickr-component'

	import Datepicker from 'vuejs-datepicker';

    import vSelect from 'vue-select'
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { required, email, integer, min } from '@validations'
    import { togglePasswordVisibility } from '@core/mixins/ui/forms'
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
	import globalAdmin from '../../model/globalAdmin';
    const moment = require('moment');

	export default {
		mixins: [globalAdmin],
	  	components: {
	  		Breadcrumb,
	  		flatPickr,
	  		Datepicker,
          	vSelect,
          	ValidationProvider,
          	ValidationObserver,
	  	},
	  	data() {
		    return {
		    	userData: JSON.parse(localStorage.getItem('userData')),
				labels: { "administrations_announcement_add": "" }, 
				form: {date: null},			  
			    selectStatus: [
			    ],
			    formAddUserful: {},
				permission: [],
			    fields: 
		    	{
			    	image: null,
			    	status: null,		    	
			    	title: null,
			    	description: null
			    },
			    file2: null,
			    date_schedule: null,
			    time_schedule: null,

			    fields: [
			    	{key: 'id', label: 'No.'},
			    	{key: 'attach_file_title', label: 'Document Name'},
			    	{key: 'attach_content_type', label: 'Remarks'},
			    	{key: 'updated_at', label: 'Uploaded Date'},
			    	{key: 'action', label: 'Actions'},
			    ],
			    files_document: [],
            }
		},		
		computed: {
          	minScheduleDate(){            
              	var d = new Date()
              	d.setDate(d.getDate())
              	var now = moment(d).format('YYYY-MM-DD')
              	return now
          	},
          	getListTime() {
	            let times = [];
	            for(let i = 0; i < 24; i++){

	            	let moment1 = this.date_schedule ? moment(this.date_schedule) : moment()

	                let time1 = moment1.startOf('date').hours(i).minutes(0).seconds(0).milliseconds(0);
	                if(moment().isSameOrBefore(time1)) {
	                    times.push({
	                        time: time1.format('HH:mm'),
	                        value: time1.format('HH:mm')
	                    })
	                }
	                let time2 = moment1.startOf('date').hours(i).minutes(30).seconds(0).milliseconds(0);
	                if(moment().isSameOrBefore(time2)) {
	                    times.push({
	                        time: time2.format('HH:mm'),
	                        value: time2.format('HH:mm')
	                    })
	                }
	            }
	            return times;
	        }
        },
		created(){
			this.permission = this.checkPermission(this.userData,"Administration");
		},
		methods: {
			validationForm() {
	              this.$refs.announcement_add.validate().then(success => {
	                return success;
	            })
	        },
			closeUpload(){
				this.fields.image = null
			},
			addAction(status){
				this.$refs.announcement_add.validate().then(success => {
					if (success){
						let userful_field = {
							image: this.fields.image,
							status: status,
		                  	title: this.fields.title,
		                  	description: this.fields.description,         
		                  	type: 0,      
		                }
						this.$store
						.dispatch('auth/addUseful', userful_field)
						.then(response => {
							this.$toast({
			                      	component: ToastificationContent,
			                      	props: {
			                          title: this.fields.title + ' Created!',
			                          variant: 'success',
			                      	},
			                	},
			                	{
			                      	position: 'top-center'
			                	}
			                )
			                this.fields.image = null
			                this.fields.title = null
			                this.fields.description = null
			                this.$router.push({ name: 'staff_administrations', query: { tab: 1} })
						})
						.catch((error) => {
							console.log(error)
							this.$toast({
			                      component: ToastificationContent,
			                      props: {
			                        title: error.response.data.message,
			                        variant: 'danger',
			                      },
			                    },
			                    {
			                      position: 'top-center'
			                    }
			                )
						})
					}
				})
			},
			cancelAction(){
    			this.$router.push({ name: 'staff_administrations', query: { tab: 1} })	
			},
			saveDraft(status){				
				this.$refs.announcement_add.validate().then(success => {
					if (success){
						this.addAction(status)
					}	
					else {
						this.$toast({
	                        component: ToastificationContent,
	                        props: {
	                          title: 'One or more fields have an error. Please check and try again.',
	                          variant: 'danger',
	                        },
	                      },
	                      {
	                        position: 'top-center'
	                      }
	                    )
					}				
				})
			},
			addActionSchedule(status){
				this.$refs.announcement_add.validate().then(success => {
					if (success){
						let userful_field = {
							image: this.fields.image,
							status: status,
		                  	title: this.fields.title,
		                  	description: this.fields.description,         
		                  	type: 0, 
		                  	published_date: this.date_schedule,  
		                  	published_time: this.time_schedule.value      
		                }
						this.$store
						.dispatch('auth/addUseful_Schedule', userful_field)
						.then(response => {
							this.$refs['modaSchedulel'].hide()	
							this.$toast({
			                      	component: ToastificationContent,
			                      	props: {
			                          title: this.fields.title + ' Created!',
			                          variant: 'success',
			                      	},
			                	},
			                	{
			                      	position: 'top-center'
			                	}
			                )
			                this.fields.image = null
			                this.fields.title = null
			                this.fields.description = null
			                this.$router.push({ name: 'staff_administrations', query: { tab: 1} })
						})
						.catch((error) => {
							console.log(error)
							this.$toast({
			                      component: ToastificationContent,
			                      props: {
			                        title: error.response.data.message,
			                        variant: 'danger',
			                      },
			                    },
			                    {
			                      position: 'top-center'
			                    }
			                )
						})
					}
				})
			},
			schedule(){				
				this.$refs.announcement_add.validate().then(success => {
					if (success){
						this.$refs['modaSchedulel'].show()		
						this.date_schedule = null
						this.time_schedule = null				
					}					
				})				
			},
			cancelSchedule(){
				this.$refs['modaSchedulel'].hide()	
				this.date_schedule = null
				this.time_schedule = null
			},
			saveSchedule(){
				this.$refs.announcement_add.validate().then(success => {
					if (success){
						if(this.date_schedule){
							this.addActionSchedule(3)
						}
						else{
							this.$toast({
			                    component: ToastificationContent,
			                    props: {
			                        title: 'Please select Date from!',
			                        variant: 'danger',
			                      },
			                    },
			                    {
			                      position: 'top-center'
			                    }
			                )
						}						
					}					
				})				
			},
			publish(status){
				this.$refs.announcement_add.validate().then(success => {
					if (success){
						this.addAction(status)
					}					
				})
			},	
			length100(e){
				return String(e).substring(0,100)
			},
			length1000(e){
				return String(e).substring(0,1000)
			}
		},
	};	

</script>

<style lang="scss">
	@import '@core/scss/vue/libs/vue-flatpicker.scss';
	@import "@/assets/scss/_ite_tab_table.scss";
	.Vue-Toastification__container.top-center {
	    top: 20px; 
	}
</style>