export default {
    methods: {
        checkPermission(userData,namePage){
            var action = [];
            userData?.permission.map(function (value) {
                if(value.module === namePage){
                    action = value.actions
                }
            })
            
            if(!action.read){
                this.$router.push({ path: `/admin/dashboard` })
            }
            return action;
        },
        checkPermission2(userData,namePage){
            var action = [];
            userData?.permission.map(function (value) {
                if(value.module === namePage){
                    action = value.actions
                }
            })
            return action;
        },
        checkPermissionOurPortfolio(userData,namePage,id = null, category = "Project"){
            if(userData.role.role_name === "Staff" || userData.role.role_name === "Management"){
                var fields = {
                    id: id,
                    category: category
                }
                this.$store.dispatch('auth/checkPermisionOurPortfolio', fields)
                .then(response => {
                    return  response.data.data;
                })
                .catch((error) => {
                    console.log(error)
                })
            }else{
                return this.checkPermission(userData,namePage);
            }
        },
    },
}