<template>
	<ul class="breadcrumb size-24 mb-0 pl-0">
        <template v-for="(routeObject, index) in routers">
            <template v-if="isLast(index)">
                <template v-if="getSublabe(routeObject)">
                    <li class="breadcrumb-item cursor-pointer" @click="click_sublabel(routeObject)">
                        {{ getSublabe(routeObject) }}
                    </li>
                </template>
                <template v-if="getSublabe_01(routeObject)">
                    <li class="breadcrumb-item cursor-pointer" @click="click_sublabel_01(routeObject)">
                        {{ getSublabe_01(routeObject) }}
                    </li>
                </template> 
                <template v-if="getSubLabel_click(routeObject)">
                    <li class="breadcrumb-item cursor-pointer" @click="click_getSubLabel(routeObject)">
                        {{ getSubLabel_click(routeObject) }}
                    </li>
                </template>
                <template v-if="getSubLabel_2_click(routeObject)">
                    <li class="breadcrumb-item cursor-pointer" @click="click_SubLabel_2(routeObject)">
                        {{ getSubLabel_2_click(routeObject) }}
                    </li>
                </template>     
                <template v-if="getNameDetail(routeObject)">
                    <li class="breadcrumb-item cursor-pointer" @click="click_namedetail(routeObject)">
                        {{ getNameDetail(routeObject) }}
                    </li>
                </template> 
                <template v-if="labelsOnlyView && getLabelOnlyView(routeObject)">
                    <li class="breadcrumb-item">
                        {{ getLabelOnlyView(routeObject) }}
                    </li>
                </template>    
                <template v-if="getTypes(routeObject)">
                    <li class="breadcrumb-item cursor-pointer" @click="click_types(routeObject)">
                        {{ getTypes(routeObject) }}
                    </li>
                </template>  
                <template v-if="getsubLabelDefault(routeObject)">
                    <li class="breadcrumb-item cursor-pointer" @click="click_action_sublabel(routeObject)">
                        {{ getsubLabelDefault(routeObject) }}
                    </li>
                </template>
                <li class="breadcrumb-item medium">
                    <span style="color: #000000CC">{{ getName(routeObject) }}</span>
                </li>
            </template> 
            <li class="breadcrumb-item" v-else><router-link style="color: #000000CC" :to="getPath(routeObject.path)">{{ getName(routeObject) }} </router-link></li>            
        </template>
    </ul>
</template>

<script>
	export default {
        props: {
            label: {
                type: String,
                required: false,
                default: () => ''
            },
            labels: {
                type: Object,
                required: false,
                default: () => {}
            },
            labelsOnlyView: {
                type: Object,
                required: false,
                default: () => {}
            },
            name_sublabel: {
                type: Object,
                required: false,
                default: () => {}
            },
            detailName: {
                type: Object,
                required: false,
                default: () => {}
            },
            subLabel_click: {
                type: Object,
                required: false,
                default: () => {}
            },
            subLabel_2_click: {
                type: Object,
                required: false,
                default: () => {}
            },
            types: {
                type: Object,
                required: false,
                default: () => {}
            },
            tabTypes: {
                type: Object,
                required: false,
                default: () => {}
            },
            action_sublabel: {
                type: Object,
                required: false,
                default: () => {}
            },
            subLabelDefault: {
                type: Object,
                required: false,
                default: () => {}
            },
        },
	  	data() {
		    return {}
		},
        computed: {
			routers() {
				let list = this.$route.matched.filter((route) => (route.meta.label));

                if(this.label && list[list.length - 1]) list[list.length - 1].meta.label = this.label;
                if(Object.getOwnPropertyNames(list).length) {
                    list.forEach(item => {
                        if(item.path.indexOf(':id') >= 0) {
                            item.path = item.path.replace(":id", this.$route.params.id)
                        }
                        if(item.path.indexOf(':project_id') >= 0) {
                            item.path = item.path.replace(":project_id", this.$route.params.project_id)
                        }
                        if(item.path.indexOf(':goal_id') >= 0) {
                            item.path = item.path.replace(":goal_id", this.$route.params.goal_id)
                        }
                        if(item.path.indexOf(':pro_id') >= 0) {
                            item.path = item.path.replace(":pro_id", this.$route.params.pro_id)
                        }
                        if(this.labels[item.name]) {
                            item.meta.label = this.labels[item.name];
                        }  
                        if( this.name_sublabel && this.name_sublabel[item.name] ) {
                            item.meta.namedetail = this.name_sublabel[item.name];
                        }  
                        if( this.detailName && this.detailName[item.name] ) {
                            item.meta.label = this.detailName[item.name]
                        }  
                        if( this.subLabel_click && this.subLabel_click[item.name]){
                            item.meta.subLabel_click = this.subLabel_click[item.name]
                        }
                        if( this.subLabel_2_click && this.subLabel_2_click[item.name]){
                            item.meta.subLabel_2_click = this.subLabel_2_click[item.name]
                        }     
                        if( this.types && this.types[item.name]){
                            item.meta.types = this.types[item.name]
                        }   
                        if( this.tabTypes && this.tabTypes[item.name]){
                            item.meta.tabIndex = this.tabTypes[item.name]
                        }            
                        if(this.labelsOnlyView && this.labelsOnlyView[item.name]) {
                            item.meta.titleOnlyView = this.labelsOnlyView[item.name];
                        }   
                        if( this.action_sublabel && this.action_sublabel[item.name] ) {
                            item.meta.action_sublabel = this.action_sublabel[item.name];
                        } 
                        if( this.subLabelDefault && this.subLabelDefault[item.name] ) {
                            item.meta.subLabelDefaultRoute = this.subLabelDefault[item.name];
                        }
                    });
                }
                return list;
			},
		},
		methods: {
            getPath(val) {
                let path = val.split("/")
                if(path.length >= 3 && path[2] != undefined && path[2].includes(":")) {
                    path[2] = this.$route.params.id;
                }
                if(this.$route.name == 'itees-strategic-detail-edit' && path.length == 6 && path[5]) {
                    path[5] = this.$route.params.goal_id;
                }
                if(this.$route.name == 'itees-strategic-detail-editprogramme' && path.length == 6 && path[5]) {
                    path[5] = this.$route.params.pro_id;
                }
                if(this.$route.name == 'our-portfolio-project-detail-edit-blank' && path.length == 5 )
                {
                    path[4] = this.$route.params.id;
                }
                if(this.$route.name == "our-portfolio-project-detail-edit-addactivity" && path.length == 5 ){
                    path[4] = this.$route.params.id;
                }
                if(this.$route.name == 'our-portfolio-project-detail-edit-editactivity' && path.length == 5 )
                {
                    path[4] = this.$route.params.id;
                }
                if(this.$route.name == 'our-portfolio-study-detail-edit' && path.length == 5 )
                {
                    path[4] = this.$route.params.id;
                }
                if(this.$route.name == "expert-our-portfolio-project-detail-edit-blank" && path.length == 5 )
                {
                    path[4] = this.$route.params.id;
                }

                if(this.$route.name == "our-portfolio-project-detail-add" && path.length == 5 )
                {
                    path[4] = this.$route.params.id;
                }
                
                // console.log('route new: ', this.$route)
                // console.log('path', path)
                // console.log('path.length', path.length)

                val = path.join('/');
                return val
            },
            getName(item) {
                return item.meta && item.meta.label ? item.meta.label : null
            },
            getLabelOnlyView(item){
                return item.meta && item.meta.titleOnlyView ? item.meta.titleOnlyView : null
            },
            getSublabe(item){
                return item.meta && item.meta.sublabel ? item.meta.sublabel : null
            },            
            getSublabe_01(item){
                return item.meta && item.meta.sublabel_01 ? item.meta.sublabel_01 : null
            },
            getNameDetail(item){         
                return item.meta && item.meta.namedetail ? item.meta.namedetail : null
            },
            getSubLabel_click(item){
                return item.meta && item.meta.subLabel_click ? item.meta.subLabel_click : null
            },
            getSubLabel_2_click(item){
                return item.meta && item.meta.subLabel_2_click ? item.meta.subLabel_2_click : null
            },
            getTypes(item){    
                return item.meta && item.meta.types ? item.meta.types : null
            },
            getsubLabelDefault(item){
                return item.meta && item.meta.subLabelDefaultRoute ? item.meta.subLabelDefaultRoute : null
            },
            // 
            click_sublabel(item){
                let link = null
                if(item.meta && item.meta.sublabel){
                    link = item.meta.navActiveLink
                }
                if(item.meta.tabIndex != null){
                    this.$router.push({ name: link, query: { tab: item.meta.tabIndex } })
                } else this.$router.push({ name: link })
            },
            click_sublabel_01(item){
                let link_sublabel = null
                if(item.meta && item.meta.sublabel_01){
                    link_sublabel = item.meta.navActiveLink
                }
                if(item.meta.tabIndex_01 != null) {
                    if(item.meta.child_tab_01 != null) {
                        this.$router.push({ name: link_sublabel, query: { tab: item.meta.tabIndex_01, child: item.meta.child_tab_01 } })
                    } else this.$router.push({ name: link_sublabel, query: { tab: item.meta.tabIndex_01 } })
                } else this.$router.push({ name: link_sublabel })
            },
            click_namedetail(item){
                let link = null

                if(item.meta && item.meta.namedetail){
                    if(item.meta && item.meta.nameDetailActive == "itees-strategic-edit"){
                        link = `${this.$route.matched[0].path}/edit/${this.$route.params.id}`
                    }
                    else{
                        link = `${this.$route.matched[0].path}/${this.$route.params.id}`
                    }                    
                }
                if(item.meta.tabIndex != null){
                    this.$router.push({ path: link, query: { tab: item.meta.tabIndex, child: item.meta.child_tab_01  } })
                } else this.$router.push({ name: item.meta.navActiveLink })
            },
            click_getSubLabel(item){

                let path_new 
                let link = null

                if(item.meta && item.meta.subLabel_click){
                    path_new = this.$route.fullPath.split('/')
                    path_new.pop()
                    link = path_new.join('/')
                }
                if( item.meta.tabIndex != null ){
                    this.$router.push({ path: link, query: { tab: item.meta.tabIndex  } })
                } else this.$router.push({ path: link })
            },
            click_SubLabel_2(item){

                let path_new 
                let link = null

                if(item.meta && item.meta.subLabel_2_click){
                    path_new = this.$route.fullPath.split('/')
                    link = path_new.slice(0, 7)
                    link = link.join('/')
                }
                if( item.meta.tabIndex != null ){
                    this.$router.push({ path: link, query: { tab: item.meta.tabIndex  } })
                } else this.$router.push({ path: link })
            },
            click_types(item){
                let link = null

                if(item.meta && item.meta.types){

                    link = `${this.$route.matched[0].path}`
                }

                if(item.meta.tabIndex != null){
                    if(item && item.meta && item.meta.parentTab){
                        this.$router.push({ path: link, query: { tab: item.meta.parentTab, type: this.tabTypes[item.name] } })
                    }
                    else{
                        this.$router.push({ path: link, query: { type: this.tabTypes[item.name] } })
                    }
                } else this.$router.push({ name: item.meta.navActiveLink })
            },
            click_action_sublabel(item){
                let link = null
                if(item.meta && item.meta.action_sublabel){

                    link = this.action_sublabel[item.name]
                    this.$router.push({ path: link })                    
                    // console.log('link: ', link)
                }
                else this.$router.push({ name: item.meta.navActiveLink })
            },
            isLast (index) {
                return index === this.routers.length - 1
            },
            isLast1 (index) { 
                if(this.routers.meta && this.routers.meta.sublabel)
                return index === this.routers.length
            },      
        }
	};	

</script>